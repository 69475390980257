import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import firebaseConfig from "../config/firebaseConfig";
import { initializeApp } from "firebase/app";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  styled,
} from "@mui/material";
import {
  Chart,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  TimeScale,
} from "chart.js";
import Header from "../components/Header";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

Chart.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  TimeScale
);

const HomePageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "50%",
  marginTop: 30,
  border: "1px solid rgb(0,113,115)",
}));

const LoadingSpinner = (props) => <CircularProgress {...props} role="progressbar" sx={{ m: 3 }} />;

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.error.main,
}));

const Homepage = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [recentAppointment, setRecentAppointment] = useState(null);
  const [weeklyAppointments, setWeeklyAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const fetchAppointments = async () => {
        try {
          const userId = currentUser.uid;
          const db = getDatabase();
          const usersRef = ref(db, "users");
          const appointmentsRef = ref(db, "appointments");
          const exercisesRef = ref(db, "exercises");

          const [usersSnapshot, appointmentsSnapshot, exercisesSnapshot] =
            await Promise.all([
              get(usersRef),
              get(appointmentsRef),
              get(exercisesRef),
            ]);

          const users = usersSnapshot.val() || {};
          const appointments = appointmentsSnapshot.val() || {};
          const exercises = exercisesSnapshot.val() || {};
          const getCurrentUserAppointments = Object.entries(appointments).filter(
            ([key, appointment]) => appointment.ptId === userId
          );
          const exercisesArray = Object.values(exercises);

          const appointmentDetails = getCurrentUserAppointments.map(
            ([key, appointment]) => {
              const exercise = exercisesArray.find(
                (ex) => ex.id === appointment.exerciseId
              );
              return {
                id: key,
                ...appointment,
                exerciseName: exercise
                  ? exercise.exerciseName
                  : "Unknown Exercise",
                patientFirstName: users[appointment.patientId]
                  ? users[appointment.patientId].firstName
                  : "Unknown Patient",
              };
            }
          );

          const now = new Date();
          const mostRecent = appointmentDetails.reduce((latest, appointment) => {
            const appointmentDate = new Date(
              appointment.date + "T" + appointment.time
            );
            if (appointmentDate < now) {
              return !latest ||
                appointmentDate > new Date(latest.date + "T" + latest.time)
                ? appointment
                : latest;
            }
            return latest;
          }, null);

          const thisWeek = appointmentDetails.filter((appointment) => {
            const appointmentDate = new Date(appointment.date);
            return (
              appointmentDate >= now &&
              appointmentDate <=
                new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
            );
          });

          setRecentAppointment(mostRecent);
          setWeeklyAppointments(thisWeek);
        } catch (error) {
          setError("Failed to fetch data");
        } finally {
          setLoading(false);
        }
      };

      fetchAppointments();
    }
  }, [currentUser]);

  const ErrorMessage = styled(Typography)({
    marginBottom: "16px",
    color: "red",
  });

  if (loading) {
    console.log("Rendering loading spinner");
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }



  return (
    <HomePageContainer>
      <Header />
      <StyledCard>
        <CardContent>
          <Typography>Most Recent Patient</Typography>
          {recentAppointment ? (
            <>
              <Typography>
                Patient Name: {recentAppointment.patientFirstName}
              </Typography>
              <Typography>
                Exercise: {recentAppointment.exerciseName}
              </Typography>
              <Typography>
                Date: {recentAppointment.date} at {recentAppointment.time}
              </Typography>
            </>
          ) : (
            <Typography>No recent appointments found.</Typography>
          )}
        </CardContent>
      </StyledCard>
      <StyledCard>
        <CardContent>
          <Typography>Appointments this week</Typography>
          {weeklyAppointments.length > 0 ? (
            weeklyAppointments.map((appointment) => (
              <Typography key={appointment.id}>
                <Typography>
                  Patient Name: {appointment.patientFirstName}
                </Typography>
                <Typography>Exercise: {appointment.exerciseName}</Typography>
                <Typography>
                  Date: {appointment.date} at {appointment.time}
                </Typography>
              </Typography>
            ))
          ) : (
            <Typography>No appointments this week.</Typography>
          )}
        </CardContent>
      </StyledCard>
    </HomePageContainer>
  );
};

export default Homepage;
