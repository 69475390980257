import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Header from '../components/Header';
import './CalendarPage.css'; 
import { getDatabase, ref, onValue } from 'firebase/database';

const CalendarPage = () => {
   const [events, setEvents] = useState([]);

   useEffect(() => {
      const db = getDatabase();
      const exercisesRef = ref(db, 'exercises'); 

      const isInDaysOfWeek = (date, daysOfWeek) => {
         const dayIndex = date.getUTCDay(); 
         const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
         const dayName = dayNames[dayIndex];
         return daysOfWeek.includes(`Every ${dayName}`);
      };

      onValue(exercisesRef, (snapshot) => {
         const data = snapshot.val();
         const formattedEvents = [];

         for (const key in data) {
            if (data.hasOwnProperty(key)) {
               const exercise = data[key];
               const { startDate, endDate, daysOfWeek, exerciseName, sets, holdTime } = exercise;

               const start = new Date(startDate);
               const end = new Date(endDate);

               for (let date = new Date(start); date <= end; date.setUTCDate(date.getUTCDate() + 1)) {
                  if (isInDaysOfWeek(date, daysOfWeek)) {
                     formattedEvents.push({
                        title: `${exerciseName}<br>${sets} sets of ${exercise.reps} reps<br>with ${holdTime} sec hold time`,
                        date: date.toISOString().split('T')[0] 
                     });
                  }
               }
            }
         }

         setEvents(formattedEvents);
      });

   }, []);

   const renderEventContent = (eventInfo) => {
      return (
         <div dangerouslySetInnerHTML={{ __html: eventInfo.event.title }} />
      );
   };

   return (
      <>
         <Header />
         <div style={{ padding: '20px' }}>
            <FullCalendar
               plugins={[dayGridPlugin]}
               initialView="dayGridMonth"
               events={events}
               eventContent={renderEventContent} 
            />
         </div>
      </>
   );
};

export default CalendarPage;
