const firebaseConfig = {
  apiKey: "AIzaSyCVbTSHNpJZRfq4nMSRRTWuoX8JHbebEFg",
  authDomain: "esurgiinc.firebaseapp.com",
  databaseURL: "https://esurgiinc-default-rtdb.firebaseio.com",
  projectId: "esurgiinc",
  storageBucket: "esurgiinc.appspot.com",
  messagingSenderId: "831263744254",
  appId: "1:831263744254:web:3e9cb456ca84b7e44e85af",
  measurementId: "G-79X4FD4Q2D"
};

export default firebaseConfig;
