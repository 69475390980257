import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Switch from '@mui/material/Switch';
import emailjs from 'emailjs-com';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import firebaseConfig from "../config/firebaseConfig";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const SettingsPage = () => {
  const [serverMode, setServerMode] = useState(false);
  const [statusText, setStatusText] = useState('Server mode is OFF');
  const timerRef = useRef(null); 

  const toggleServerMode = () => {
    setServerMode((prev) => !prev);
  };

  useEffect(() => {
    if (!serverMode && timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
      setStatusText('Server mode is OFF.');
    }

    if (serverMode) {
      setStatusText('Server mode is ON. Fetching orders and sending emails.');

      fetchAndSendEmails();

      timerRef.current = setInterval(() => {
        fetchAndSendEmails();
      }, 12 * 60 * 60 * 1000); 
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [serverMode]);

  const fetchAndSendEmails = async () => {
    if (!serverMode) return; 

    try {
      const db = firebase.database();
      const usersRef = db.ref('prescriptions');
      
      const snapshot = await usersRef.once('value');
      const prescriptions = snapshot.val();
  
      if (prescriptions) {
        const currentDate = new Date().toISOString().split('T')[0]; 
  
        await Promise.all(Object.keys(prescriptions).map(async (key) => {
          const prescription = prescriptions[key];
          const { startDate, endDate, exerciseName, sets, reps, holdTime, daysOfWeek, email } = prescription;
  
          console.log(`Processing prescription ${key}:`, { email, exerciseName, sets, reps, holdTime, daysOfWeek });
  
          if (currentDate >= startDate && currentDate <= endDate) {
            const emailParams = {
              to_email: email, 
              subject: 'Exercise Reminder',
              message: `This is a reminder to perform the exercises prescribed by your physical therapist: ${exerciseName}, ${sets} sets of ${reps} reps with ${holdTime} seconds hold time on ${daysOfWeek.join(', ')}.`,
            };
  
            try {
              await emailjs.send('service_key', 'template_key', emailParams, 'userKey');
            } catch (emailError) {
              console.error('Error sending email:', JSON.stringify(emailError, null, 2));
            }
          }
        }));
      } else {
        console.log('No prescriptions found.');
      }
    } catch (error) {
      console.error('Error fetching prescriptions or sending email:', JSON.stringify(error, null, 2));
    }
  };

  return (
    <div>
      <Header />
      <h2 style={{ color: 'black' }}>Server Mode</h2>
      <Switch
        checked={serverMode}
        onChange={toggleServerMode}
        name="serverModeSwitch"
        inputProps={{ 'aria-label': 'server mode toggle' }}
      />
      <p style={{ color: 'black' }}>{statusText}</p>
    </div>
  );
}

export default SettingsPage;
