import React, { useState, useEffect } from "react";
import { Typography, Button, CircularProgress, styled } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import firebaseConfig from "../config/firebaseConfig";
import Header from "../components/Header";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const MyAccountContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "20px",
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: "rgb(0,113,115)",
  fontFamily: "Montserrat",
  fontWeight: "bold",
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontFamily: "Montserrat",
  fontWeight: "bold",
}));

const LoadingSpinner = (props) => <CircularProgress {...props} sx={{ m: 3 }} />;

const MyAccountPage = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCurrentUser = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
    return () => getCurrentUser();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const db = firebase.database();
        const usersRef = db.ref("users");

        const snapshot = await usersRef.once("value");
        const users = snapshot.val();
        const userData = users[currentUser.uid];

        if (userData) {
          setUserInfo(userData);
        } else {
          setError("User data not found");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user info: ", error);
        setError("Error fetching user data");
        setLoading(false);
      }
    };

    fetchUserInfo();
    return () => {
      const db = firebase.database();
      const usersRef = db.ref("users");
      usersRef.off();
    };
  }, [currentUser]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <MyAccountContainer>
        <Header />
        <TitleTypography variant="h5">My Account</TitleTypography>
        {userInfo ? (
          <div>
            <InfoTypography variant="h6">
              Name: {userInfo?.firstName} {userInfo.lastName}
            </InfoTypography>
            <InfoTypography variant="h6">
              Email: {currentUser?.email}
            </InfoTypography>
            <InfoTypography variant="h6">
              Phone Number: {currentUser?.phoneNumber}
            </InfoTypography>
            <InfoTypography variant="h6">
              Address: {currentUser?.address}
            </InfoTypography>
            <InfoTypography variant="h6">
              User Type: {userInfo?.userType === "PT" ? "Physical Therapist" : "Patient"}
            </InfoTypography>
            <InfoTypography variant="h6">
              User Id: {userInfo?.userID}
            </InfoTypography>
          </div>
        ) : (
          <InfoTypography>No user information available</InfoTypography>
        )}
      </MyAccountContainer>
    </>
  );
};

export default MyAccountPage;
